import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NousSoutenirPage = () => (
  <Layout>
    <SEO title="Soutenez nous" description="Soutenez la compagnie Ailes de cire." />
    <section id="nous-soutenir-banner">
      <h1>Soutenez nos projets</h1>
    </section>
    <section className="main container">
      <iframe id="haWidget" allowtransparency="true" scrolling="auto" src="https://www.helloasso.com/associations/compagnie-ailes-de-cire/formulaires/2/widget" style={{width:`100%`, height:`2400px`, border:`none`}} onload="window.scroll(0, this.offsetTop)"></iframe>
    </section>
  </Layout>
)

export default NousSoutenirPage
